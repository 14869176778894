import JSBI from 'jsbi'

// exports for external consumption
export type BigintIsh = JSBI | bigint | string
export type InitCodeHashes = { [chainId in ChainId]: string }

export enum ChainId {
  MAINNET = 1,
  ROPSTEN = 3,
  RINKEBY = 4,
  GÖRLI = 5,
  KOVAN = 42,
  BSC_MAINNET = 56,
  BSC_TESTNET = 97,
  HECO_MAINNET = 128,
  HECO_TESTNET = 256,
  AVAX_MAINNET = 43114,
  AVAX_TESTNET = 43113,
}

export enum TradeType {
  EXACT_INPUT,
  EXACT_OUTPUT
}

export enum Rounding {
  ROUND_DOWN,
  ROUND_HALF_UP,
  ROUND_UP
}

export const FACTORY_ADDRESS_ETH = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f'
// 正式hurricane
export const FACTORY_ADDRESS_BSC = '0xd894278927fd28fC55D1CEfcc8FEbC7f962Bf0D5'
// 测试hurricane
export const FACTORY_ADDRESS_HECO = '0xc723bab860C945f21126d19dDBF80bb169bffE9A'
// 正式hurricane
export const FACTORY_ADDRESS_AVAX = '0x8e6F4Af0B6c26d16feBdD6f28FA7C694bD49c6BF'

export const FACTORY_ADDRESS_ETH_TESTNET = '0x5C69bEe701ef814a2B6a3EDD4B1652CB9cc5aA6f'
// 正式pancake
export const FACTORY_ADDRESS_BSC_TESTNET = '0x240cb8EE3D74B8F4fb71c8c70EA823e6a442b054'
// 测试hurricany
export const FACTORY_ADDRESS_HECO_TESTNET = '0xc723bab860C945f21126d19dDBF80bb169bffE9A'
// 测试hurricane
export const FACTORY_ADDRESS_AVAX_TESTNET = '0x240cb8EE3D74B8F4fb71c8c70EA823e6a442b054'



export const INIT_CODE_HASH_ETH = '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f'
export const INIT_CODE_HASH_BSC = '0x713e75569d04db58edeec3b995accab6bbf68dd60063cbe0cb88eba179dc72d0'
export const INIT_CODE_HASH_HECO = '0xe5b1bf3e41de3db5fbd571034a3f305b6b4309ac4a70fd870711c10e456b024f'
export const INIT_CODE_HASH_AVAX = '0xa55deec6f1475dc6e886e80962756c99724ced4a02f0765e7db4012ad0cea895'

export const INIT_CODE_HASH_ETH_TESTNET = '0x96e8ac4277198ff8b6f785478aa9a39f403cb768dd02cbee326c3e7da348845f'
export const INIT_CODE_HASH_BSC_TESTNET = '0xcba19b62dad4e75424fa2f7f680e718de64d47dcdc2cc1b5a0800ef62305770a'
export const INIT_CODE_HASH_HECO_TESTNET = '0xe5b1bf3e41de3db5fbd571034a3f305b6b4309ac4a70fd870711c10e456b024f'
export const INIT_CODE_HASH_AVAX_TESTNET = '0xa55deec6f1475dc6e886e80962756c99724ced4a02f0765e7db4012ad0cea895'


export const INIT_CODE_HASHES: InitCodeHashes = {
  [ChainId.MAINNET]: INIT_CODE_HASH_ETH,
  [ChainId.ROPSTEN]: INIT_CODE_HASH_ETH,
  [ChainId.RINKEBY]: INIT_CODE_HASH_ETH,
  [ChainId.GÖRLI]: INIT_CODE_HASH_ETH,
  [ChainId.KOVAN]: INIT_CODE_HASH_ETH,
  [ChainId.BSC_MAINNET]: INIT_CODE_HASH_BSC,
  [ChainId.BSC_TESTNET]: INIT_CODE_HASH_BSC_TESTNET,
  [ChainId.HECO_MAINNET]: INIT_CODE_HASH_HECO,
  [ChainId.HECO_TESTNET]: INIT_CODE_HASH_HECO_TESTNET,
  [ChainId.AVAX_MAINNET]: INIT_CODE_HASH_AVAX,
  [ChainId.AVAX_TESTNET]: INIT_CODE_HASH_AVAX_TESTNET,
}

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const TWO = JSBI.BigInt(2)
export const THREE = JSBI.BigInt(3)
export const FIVE = JSBI.BigInt(5)
export const TEN = JSBI.BigInt(10)
export const _100 = JSBI.BigInt(100)
export const _997 = JSBI.BigInt(997)
export const _995 = JSBI.BigInt(995)
export const _1000 = JSBI.BigInt(1000)

export enum SolidityType {
  uint8 = 'uint8',
  uint256 = 'uint256'
}

export const SOLIDITY_TYPE_MAXIMA = {
  [SolidityType.uint8]: JSBI.BigInt('0xff'),
  [SolidityType.uint256]: JSBI.BigInt('0xffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffffff')
}
